import { Button, Grid, HStack, Heading, Progress, useMediaQuery } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { format } from "date-fns";
import { theme } from "../chakra-theme";
import { CheckCircleIcon, LockIcon } from "@chakra-ui/icons";
import bgImage from '../assets/electronic-background.jpg';

export const TierTracker: React.FC = observer(() => {
  const user = authStore.user;
  useEffect(() => {
    if (!user) {
      const u = authStore.getUser();
      if (u) authStore.reFetchUser(u?.id)
    }
  }, [ user ]);
  const currentTierStructure = user?.tiers.find((t) => t.structure.id === "primary")
  let val = currentTierStructure?.next ? 0 : 100
  if (currentTierStructure?.next && user) {
    val = 100 * user.spendable / currentTierStructure?.next.points;
  }
  const nextTier = currentTierStructure?.next ? currentTierStructure.next : {
    id: currentTierStructure?.id + '2',
    name: currentTierStructure?.name,
    points: currentTierStructure?.threshold || 0,
  }
  const isMaxTier = !currentTierStructure?.next;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  return (
    <Grid placeContent="center" backgroundPosition="center" backgroundImage={bgImage}>
      <Grid 
        w={isDesktop ? '750px' : undefined}
        gap="1em"
        color="brand.100"
        alignItems="center"
        justifyItems="center"
        padding="1em"
        templateRows="2em 1em 3em auto auto"
        bg="rgba(0, 0, 0, 0.5)"
      >
        <Heading size={ isDesktop ? '2xl' :"lg"}>WELCOME BACK, {user?.first_name.toUpperCase()}!</Heading>
        <Heading size={ isDesktop ? 'xl' : "xs"}>Part of the Community since <span style={{color: theme.colors.brand[500]}}>{user?.optin_at && format(user.optin_at * 1000, 'LLLL y')}</span></Heading>
        <Grid w="100%"  templateColumns="1fr 1fr">
          <HStack>
            <CheckCircleIcon />
            <Grid>
              <Heading size="sn">{currentTierStructure?.name.toUpperCase()}</Heading>
              <Heading size="xs" color="brand.500">Unlocked!</Heading>
            </Grid>
          </HStack>
          <HStack placeSelf="center end">
            <Grid>
              <Heading textAlign="end" size="sm">{nextTier?.name?.toUpperCase()}</Heading>
              <Heading size="xs" color="brand.500">{isMaxTier ? 'Unlocked!' : `${nextTier.points - Number(user?.spendable)} Points to Unlock`}</Heading>
            </Grid>
            {isMaxTier ? <CheckCircleIcon /> : <LockIcon />}
          </HStack>
        </Grid>
        <Grid w="100%">
          <Progress rounded=".5em" h={isDesktop ? '1.5em' :  undefined} value={val} />
        </Grid>
        <Button fontSize={isDesktop ? 'large' : undefined} variant="solidSecondary" onClick={() => document.location.pathname = '/activity-history'}>View My Activity History</Button>
      </Grid>
    </Grid>
  )
});
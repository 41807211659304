import { Grid, Text, Box, Image, Button, useMediaQuery, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { format } from "date-fns";
import { Challenge } from "../store/ChallengesStore";
import { VideoModal } from "./VideoModal";
import { CheckCircleIcon } from "@chakra-ui/icons";

type ChallengeCardProps = {
  challenge: Challenge;
}

export const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge }) => {
  const totalCompleted = challenge.progress.criteria.reduce((acc, c) => acc += c.current, 0);
  const totalCriteria = challenge.progress.criteria.reduce((acc, c) => acc += c.threshold, 0);
  const percentage = totalCompleted > 0 ? 100 * totalCompleted / totalCriteria : 0;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  const {isOpen, onClose, onOpen} = useDisclosure()
  const handlePress = useCallback(() => {
    if (challenge.url && challenge.url.includes('v=')) {
      onOpen();
    }
  }, [challenge, onOpen])
  return (
    <Grid
      borderRadius="10px"
      position="relative"
      overflow="hidden"
      w={isDesktop ? '450px' : undefined}
      bg="black"
      //p="2em 1em"
      _hover={{
        bg: 'rgba(255,255,255,.2)',
        transition: 'all .3s ease'
      }}
    >
      <Grid>
        {challenge.ends_at && <Text fontStyle="italic" alignSelf="end" color="brand.100">Until {format(new Date(challenge.ends_at), 'MMM eo')}</Text>}
        <Grid pt="1em" px="1em" templateColumns="auto auto" gap="0.5em">
          <Text textAlign="start" fontWeight="bold" color="brand.500" fontSize="16px" noOfLines={1} textOverflow="ellipsis">{challenge.title.toUpperCase()}</Text>
          <Text textAlign="end" fontWeight="bold" color="brand.100" fontSize="16px">+{challenge.points} POINTS</Text>
        </Grid>
        <Text pl="1em" color="brand.100" textAlign="start">{challenge.description}</Text>
      </Grid>
      <Box mt="1em" position="relative" overflow="hidden" maxH={isDesktop ? '200px' : undefined}>
        <Image w="100%" src={challenge.image.medium} />
        <Text padding="1em" bg="rgba(0,0,0,.5)" position="absolute" right="1em" top="1em" color="brand.100">{totalCompleted} / {totalCriteria}</Text>
      </Box>
      {challenge.cta_label
        && <Button
            rightIcon={percentage === 100 ? <CheckCircleIcon textAlign="end" fontSize="1em" color="white" /> : undefined}
            onClick={percentage !== 100 ? handlePress : undefined}
            mt="1em"
            p="0.6em 3em"
            placeSelf="end"
            fontSize="14px"
            variant="solidSecondary">
              {percentage !== 100 ? challenge.cta_label.toUpperCase() : 'COMPLETED'}
            </Button>
      }
      {challenge.url && <VideoModal isOpen={isOpen} onClose={onClose} urls={challenge.url} />}
    </Grid>
  )
}
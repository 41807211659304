import { Grid, Heading, Link, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

export const Footer: React.FC = () => {
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  return (
    <Grid 
      gap="16px"
      backgroundColor="brand.100"
      color="brand.700"
      mt="2em"
      pt="2em"
      pl="1em"
      pb="3em"
      autoFlow={isDesktop ? 'column' : 'row'}
      placeItems="center"
      >
      {isDesktop && <Heading textAlign="center" size="lg">Seagate</Heading>}
      <Grid className="footer-items">
        <Grid>
          {!isDesktop && <Heading textAlign="center" size="lg">Seagate</Heading>}
          <Link textAlign="center" variant="black" href="#">Who We Are</Link>
          <Link textAlign="center" variant="black" href="#">Locations</Link>
          <Link textAlign="center" variant="black" href="#">Terms and Conditions</Link>
        </Grid>
      </Grid>
      <Grid gap="8px" placeContent="center">
        <Text textAlign="center" fontSize="11px">©Seagate 2024 </Text>
      </Grid>
    </Grid>
  )
}
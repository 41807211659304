import { Button, Grid, Image, Link, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { HamburgerIcon } from '@chakra-ui/icons'
import { SideBar } from "./SideBar";
import logoImg from '../assets/logo-with-text.svg';

const routes: { id: string; label: string; path: string; renderCustom?: () => JSX.Element }[] = [
  {
    id: 'side-1',
    label: 'Home',
    path: '/',
  },
  {
    id: 'side-2',
    label: 'Activity History',
    path: '/activity-history',
  },
  {
    id: 'side-3',
    label: 'Redeem Rewards',
    path: '/rewards',
  },
  {
    id: 'side-4',
    label: 'Log Out',
    path: '/login',
    renderCustom: () => (
      <Link
        fontSize="1.5em"
        color="brand.700"
        onClick={() => {
          localStorage.removeItem('user');
          document.location = '/login';
        }}
        key="side-4"
      >
        Log Out
      </Link>
    )
  }
]

export const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  return (
    <Grid
        zIndex={10}
        position="fixed"
        top={0}
        left={0}
        right={0}
        bg="brand.100"
        color="brand.500"
        alignItems="center"
        pl='1em' 
        templateColumns={!isDesktop ? "1fr 4em" : "auto 1fr"}
        h="60px"
        boxSizing="border-box"
        boxShadow="2px 0px 2px 0px var(--chakra-colors-brand-500)"
      >
      <Image h="2em" src={logoImg} />
      {isDesktop && <Grid placeSelf="center end" autoFlow="column" gap="1em">
            {routes.map((route) => {
              if (route.renderCustom) {
                return route.renderCustom();
              }
              return <Link color="brand.700" fontSize="1.5em" key={route.id} href={route.path}>{route.label}</Link>
            })}
      </Grid>}
      {!isDesktop && <Button
        w="4em"
        h="4em"
        variant="unstyled"
        leftIcon={<HamburgerIcon />}
        onClick={onOpen}
      />}
      {!isDesktop && <SideBar isOpen={isOpen} onClose={onClose} routes={routes} />}
    </Grid>
  )
}
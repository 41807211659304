import { Box, Grid, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { LoginForm } from '../components/LoginForm';
import loginImg from '../assets/logo-with-text.svg';

export const Login: React.FC = () => {
  return (
    <Grid templateRows="auto 1fr" h="100vh">
      <Grid position="relative" p="32px 1.5em" templateRows="1fr auto" gap="2em">
        {/*<Image w="200%" h="100%" zIndex="-1" position="absolute" src={loginImg} />*/}
        <Image src={loginImg} />
        <Text fontSize="2xl">Welcome to Seagate</Text>
      </Grid>
      <Box>
        <LoginForm />
      </Box>
    </Grid>
  )
}
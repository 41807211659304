import { Button, Grid, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { Reward } from "../store/RewardsStore";
import { LockIcon } from "@chakra-ui/icons";

type RewardItemProps = {
  reward: Reward;
  hasEnoughPoints: boolean;
  onClaim: () => void;
  spendable: number;
}

export const RewardItem: React.FC<RewardItemProps> = ({ reward, hasEnoughPoints, onClaim, spendable }) => {
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  return (
    <Grid
      maxW="500px"
      minW="350px"
      borderRadius="4px"
      overflow="hidden"
      m="0 1em"
      maxH={!isDesktop ? "300px" : undefined}
    >
      <Grid borderRadius="10px" overflow="hidden" gap="0.5em">
        <Grid position="relative">
          <Image opacity={hasEnoughPoints ? 1 : 0.5} src={reward.image.large} />
          {!hasEnoughPoints && 
            <Text position="absolute" top="0" right="0" left="0" maxH="1.5em" textAlign="center" bg="brand.500">EARN {reward.points - spendable} MORE POINTS</Text>
          }
        </Grid>
        <Grid autoFlow="column" placeContent="start" gap="0.5em">
          <Text textAlign="start" fontSize="16px" noOfLines={1} textOverflow="ellipsis" color="brand.500" fontWeight="bold">{reward.title.toUpperCase()}</Text>
          <Text color="brand.100" size="lg" fontWeight="bold" textAlign="start">
            -{reward.points} POINTS
          </Text>
        </Grid>
        <Text color="brand.100" textAlign="start">{reward.description}</Text>
        <Button
          variant="solidSecondary"
          loadingText="Loading..."
          isDisabled={!hasEnoughPoints}
          onClick={hasEnoughPoints ? onClaim : undefined}
          placeSelf="end"
          p="0.5em 3em"
          justifySelf="end"
          mt="2em"
          color={!hasEnoughPoints ? 'brand.700' : undefined}
          rightIcon={!hasEnoughPoints ? <LockIcon /> : undefined}
        >
            CLAIM REWARD
        </Button>
        </Grid>
      </Grid>
  )
}
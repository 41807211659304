import { makeAutoObservable } from 'mobx';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  authToken?: string;
  score: number;
  spent: number;
  spendable: number;
  optin_at: number;
  tiers: UserTier[];
}

type UserTier = {
  id: string;
  name: string;
  since: string;
  threshold: number;
  structure: {
    id: string;
    name: string;
  };
  next: {
    id: string;
    name: string;
    points: number;
  }
}

class AuthStore {
  user: User | undefined;
  isLoading: boolean = false;
  authUrl?: string = process.env.REACT_APP_AUTH_API_URL;
  customerUrl?: string = process.env.REACT_APP_CUSTOMER_API_URL;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: User) {
    this.user = user;
  }

  removeUser() {
    this.user = undefined;
  }

  async login(username: string, password: string) {
    this.isLoading = true;
    if (!this.authUrl) {
      console.error('process.env.REACT_APP_AUTH_API_URL is not defined and it is needed by the auth store');
      return
    }
    const res =  await fetch(`${this.authUrl}/login`,
      {
        method: 'POST',
        body: JSON.stringify({
          username,
          password,
        }),
        headers: {
          "Content-Type": "application/json",
          'Accept-Language': 'en',
        }
      }
    );
    const json = await res.json();
    if (json.error) {
      this.isLoading = false;
      return { error: json.error.message }
    } else {
      localStorage.setItem('user', JSON.stringify(json));
      this.user = json;
    }
    this.isLoading = false;
  }

  getUser () {
    if (!this.user) {
      const userStr = localStorage.getItem('user');
      if (userStr) {
        this.user = JSON.parse(userStr);
      }
    }
    return this.user;
  }

  async reFetchUser(customerId: string) {
    if (!this.customerUrl) {
      console.error('process.env.REACT_APP_CUSTOMER_API_URL is not defined and it is needed by the auth store');
      return
    }
    const res = await fetch(`${this.customerUrl}/${customerId}`, { headers: { 'Accept-Language': 'en' } });
    const json = await res.json();
    if (json.id) {
      this.user = json;
      localStorage.setItem('user', JSON.stringify(json));
    }
    return this.user;
  }

 async logout() {
  localStorage.removeItem('user');
 }
}

export const authStore = new AuthStore();